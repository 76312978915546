<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
        { validator: inputValidator, trigger: 'blur' },
        { validator: inputValidator, trigger: 'change' },
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>

      <el-input
        ref="input"
        v-model="localValue"
        v-mask="vmask"
        :name="name"
        :size="size"
        :type="isPassword ? 'password' : 'text'"
        :show-password="isPassword"
        :placeholder="placeholder || $locale.main.placeholder.string_field"
        :readonly="_isReadonly"
        @input="$event => onInput($event)"
        @keyup.enter.native="$event => onEnter()"
      ></el-input>

      <template
        v-slot:error="{ error }">
        <div>
          <div
            v-for="(_error, index) in error.split('|')"
            :key="index"
            style="position:relative"
            class="el-form-item__error"
          >
            {{ _error }}
          </div>
        </div>
      </template>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import maskMixin from '@/mixins/maskMixin.js'
import InputLabel from '@/mixins/inputLabel.js'

export default {
  name: 'a-string',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, registryMixin, maskMixin, InputLabel],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    label: {
      type: String,
      description: 'name'
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    defaultValue: {
      type: String,
      description: 'default_name'
    },
    mask: {
      type: String,
      frozen: true
    },
    inputType: {
      type: Object,
      editor: 'InputType',
      default: () => ({
        isMaskGuide: true,
        isSaveMasked: true
      })
    },
    placeholder: {
      type: String,
      description: 'placeholder'
    },
    tooltip: {
      type: String,
      description: 'tooltip'
    },
    emitOnEnter: {
      type: Boolean,
      description: 'emit_on_enter'
    }
  },
  data () {
    return {
      localValue: this.value || this.defaultValue,
      passwordRules: {}
    }
  },
  computed: {
    isPassword () {
      return this.inputType?.type === 'password'
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    value (value) {
      this.localValue = value
      this.onInput(this.localValue)
    }
  },
  async mounted () {
    if (this.mask) {
      this.$emit('change-property', { name: 'inputType', value: { mode: 'maskSymbolsVmask', maskSymbolsVmask: this.mask, isAdvanced: true } })
      this.$emit('change-property', { name: 'mask', value: null })
    }
    if (this.value != null) {
      this.localValue = this.value
      this.$emit('input', this.localValue)

      return
    }

    if (this.defaultValue) {
      let value = this.defaultValue
      if (value === '{{user_id}}') {
        value = this.$store.getters['Authorization/userId']
      }
      if (value === '{{role_id}}') {
        value = this.$store.getters['Authorization/roleId']
      }
      if (/user.attr_[0-9]+_/i.test(value)) {
        value = await this.$store.getters['Authorization/userAttributeData'](value.match(/attr_[0-9]+_/gi)[0])
      }
      if (Array.isArray(value)) {
        let temp = []
        value.forEach((item) => {
          temp.push(item.name || item)
        })
        this.localValue = temp.join(', ')
      } else {
        this.localValue = value
      }
      this.$emit('input', this.localValue)
    }
  },
  methods: {
    async getPasswordRules () {
      let { data } = await this.$http.get(`${this.$config.api}/v2/accesseditor/password/rules`)
      return data.rules
    },
    onInput (event) {
      // Вызывается из el-input с заполненным event и из maskMixin.js
      let valueToEmit = event

      if (this.imask) {
        this.localValue = event
        valueToEmit = (this.inputType.isSaveMasked) ? this.imask.value : this.imask.unmaskedValue
      }

      if (!this.emitOnEnter) {
        this.$emit('input', valueToEmit)
      }
    },
    onEnter () {
      let valueToEmit = this.localValue

      if (this.imask) {
        valueToEmit = (this.inputType.isSaveMasked) ? this.imask.value : this.imask.unmaskedValue
      }

      if (this.emitOnEnter) {
        this.$emit('input', valueToEmit)
      }
    },
    async inputValidator (rule, value, callback) {
      // Работает только при заполненном "Атрибут"
      let isValid = true
      let message = ''
      if (this.inputType && (this.inputType.type || this.inputType.isAdvanced /* Выбран "Тип поля" или выбран "Расширенный" */)) {
        // Валидатор регулярного выражения
        if (['validatorRegex', 'validatorTemplateRegex'].includes(this.inputType.mode) && this.inputType.validatorRegex) {
          if (!RegExp(this.inputType.validatorRegex, this.inputType.validatorRegexFlags || '').test(this.localValue) && (this.localValue || '').length > 0) {
            console.log(this.label, this.name)
            isValid = false
            message += 'Некорректное значение'
          }
        }

        // Валидатор маски
        if (this.inputType.isValidateMask && this.imask && this.imask.isValid === false) {
          isValid = false
          message += 'Значение не совпадает с маской'
        }

        if (this.inputType.type === 'password') {
          if (Object.keys(this.passwordRules).length === 0) {
            this.passwordRules = await this.getPasswordRules()
          }
          if (this.localValue) {
            let { data } = await this.$http.post(`${this.$config.api}/v2/accesseditor/password/check`, {
              password: this.localValue
            }, {
              hideNotification: true
            })
            isValid = data.valid
            if (!isValid) {
              let errorBag = []
              if (data['lengthValid'] === false) errorBag.push(`${this.$t('access_editor.users_item.rules_form.passwordLength')}: ${this.passwordRules['length']['min']}-${this.passwordRules['length']['max']} ${this.$t('access_editor.users_item.rules_form.passwordChars')}`)
              if (data['uppercaseValid'] === false) errorBag.push(`${this.$t('access_editor.users_item.rules_form.passwordQuantity')} ${this.$t('access_editor.users_item.rules_form.passwordUppercase')}: ${this.passwordRules['uppercase']['min']}-${this.passwordRules['uppercase']['max']} ${this.$t('access_editor.users_item.rules_form.passwordChars')}`)
              if (data['lowercaseValid'] === false) errorBag.push(`${this.$t('access_editor.users_item.rules_form.passwordQuantity')} ${this.$t('access_editor.users_item.rules_form.passwordLowercase')}: ${this.passwordRules['lowercase']['min']}-${this.passwordRules['lowercase']['max']} ${this.$t('access_editor.users_item.rules_form.passwordChars')}`)
              if (data['numbersValid'] === false) errorBag.push(`${this.$t('access_editor.users_item.rules_form.passwordQuantity')} ${this.$t('access_editor.users_item.rules_form.passwordNumbers')}: ${this.passwordRules['numbers']['min']}-${this.passwordRules['numbers']['max']} ${this.$t('access_editor.users_item.rules_form.passwordChars')}`)
              if (data['symbolsValid'] === false) errorBag.push(`${this.$t('access_editor.users_item.rules_form.passwordQuantity')} ${this.$t('access_editor.users_item.rules_form.passwordSpecChars')}: ${this.passwordRules['symbols']['min']}-${this.passwordRules['symbols']['max']} ${this.$t('access_editor.users_item.rules_form.passwordChars')}`)
              message = errorBag.join('|')
            }
          }
        }
      }

      return (!isValid) ? callback(message) : callback()
    }
  }
}
</script>

<style scoped>
* /deep/ .el-form-item__error {
  /* position: relative; */
}
</style>
